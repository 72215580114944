/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Group from "vux/src/components/group/index.vue";
import Cell from "vux/src/components/cell/index.vue";
import Previewer from "vux/src/components/previewer/index.vue";
import XTextarea from "vux/src/components/x-textarea/index.vue";
import XButton from "vux/src/components/x-button/index.vue";
import Confirm from "vux/src/components/confirm/index.vue";
import TransferDom from "vux/src/directives/transfer-dom/index.js";
export default {
  name: "RepairAttendanceDetail",
  directives: {
    TransferDom: TransferDom
  },
  components: {
    Group: Group,
    Cell: Cell,
    XButton: XButton,
    Confirm: Confirm,
    Previewer: Previewer,
    XTextarea: XTextarea
  },
  data: function data() {
    return {
      applyMan: this.$utils.Store.getItem('userLname'),
      apply: {
        proposer: '小龙女',
        section: '产品设计部',
        type: 0,
        startTime: '2018-09-12 08:30',
        endTime: '2018-09-12 17:30',
        totalTime: 7.5,
        reason: '阿斯兰的方式康师傅来看郭德纲的理工科的风格爱上了对方可发',
        state: '0',
        approver: '隔壁老王'
      },
      repairDay: '',
      //补出勤日期
      approvalData: [{
        name: "小龙女",
        opinion: "发起请求",
        sj: "2018-09-28 09:28",
        type: 1
      }, {
        name: "小虾米",
        opinion: "同意",
        sj: "2018-09-28 09:28",
        type: 2
      }, {
        name: "大肉盾",
        opinion: "待审批",
        sj: "2018-09-28 09:28",
        type: 3
      }],
      approver: [//抄送人数组
      {
        name: '刘来馨'
      }, {
        name: '徐庆宁'
      }],
      img: [{
        msrc: 'http://ww1.sinaimg.cn/thumbnail/663d3650gy1fplwu9ze86j20m80b40t2.jpg',
        src: 'http://ww1.sinaimg.cn/large/663d3650gy1fplwu9ze86j20m80b40t2.jpg'
      }, {
        msrc: 'http://ww1.sinaimg.cn/thumbnail/663d3650gy1fplwvqwuoaj20xc0p0t9s.jpg',
        src: 'http://ww1.sinaimg.cn/large/663d3650gy1fplwvqwuoaj20xc0p0t9s.jpg'
      }, {
        msrc: 'http://ww1.sinaimg.cn/thumbnail/663d3650gy1fplwwcynw2j20p00b4js9.jpg',
        src: 'http://ww1.sinaimg.cn/large/663d3650gy1fplwwcynw2j20p00b4js9.jpg'
      }],
      imgList: [],
      options: {
        getThumbBoundsFn: function getThumbBoundsFn(index) {
          var thumbnail = document.querySelectorAll('.previewer-demo-img')[index];
          var pageYScroll = window.scrollY || document.documentElement.scrollTop;
          var rect = thumbnail.getBoundingClientRect();
          return {
            x: rect.left,
            y: rect.top + pageYScroll,
            w: rect.width
          };
        }
      },
      showRepeal: false,
      //是否显示撤销弹窗
      showOperate: false,
      //是否显示审批弹窗
      xh: '',
      //申请序号
      type: '',
      //申请类型 0 请假；1 出差；2 外出；3 加班；4 调班；5 补出勤; 6 合同
      genre: 0,
      //类型  1：申请的  2：审批的  3：抄送的
      result: 0,
      //审批结果  1：同意  2：拒绝
      checkId: '',
      //审批ID
      reason: '' //审批意见

    };
  },
  computed: {
    isRepeal: function isRepeal() {
      /*是否显示撤销按钮 */
      if (this.genre == 1 && (this.apply.flag == 0 || this.apply.flag == 1)) {
        return true;
      } else {
        return false;
      }
    },
    isOperate: function isOperate() {
      /* 是否显示审批按钮*/
      if (this.genre == 2 && (this.apply.flag == 0 || this.apply.flag == 1)) {
        return true;
      } else {
        return false;
      }
    },
    filterData: function filterData() {
      var _this = this;

      /* 过滤审批人数组，只返回审批状态为5的当前审批人*/
      return this.approver.filter(function (item) {
        return item.type === 5 && _this.$utils.Store.getItem('userSerial') == item.userSerial;
      });
    }
  },
  methods: {
    /**
     *  function获取补出勤（补出勤）详情接口
     *  @wzx
     *  @2019-01-08
     **/
    getRepairDetail: function getRepairDetail() {
      var _this2 = this;

      this.$utils.Tools.loadShow();
      this.$api.Repair.getRepairDetail({
        xh: this.xh,
        userSerial: this.$utils.Store.getItem('userSerial')
      }).then(function (response) {
        _this2.$utils.Tools.loadHide();

        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this2.$code.success) {
          _this2.$utils.Tools.toastShow(msg);
        } else {
          _this2.apply = data.midData; // this.imgList = data.midData.img;
          // this.imgList = this.img;

          _this2.approver = data.botData;
          _this2.checkId = data.midData.checkId;
          _this2.xh = data.midData.xh;

          for (var i = 0; i < data.midData.img.length; i++) {
            _this2.imgList.push({
              src: data.midData.img[i],
              msrc: data.midData.img[i]
            });
          }

          if (data.midData.sbsj) {
            _this2.repairDay = data.midData.sbsj.substring(0, 10);
          } else {
            _this2.repairDay = data.midData.xbsj.substring(0, 10);
          }
        }
      }).catch(function () {
        _this2.$utils.Tools.httpError();
      });
    },

    /**
     *  function点击撤销申请按钮
     *  @wzx
     *  @2018-12-26
     **/
    repealClick: function repealClick() {
      this.showRepeal = true;
    },

    /**
     *  function点击审批同意按钮
     *  @wzx
     *  @2018-12-26
     **/
    agreeClick: function agreeClick() {
      this.result = 1;
      this.showOperate = true;
      this.reason = '';
    },

    /**
     *  function点击审批拒绝按钮
     *  @wzx
     *  @2018-12-26
     **/
    refuseClick: function refuseClick() {
      this.result = 2;
      this.showOperate = true;
      this.reason = '';
    },

    /**
     *  function审批接口
     *  @wzx
     *  @2018-12-26
     **/
    onOperate: function onOperate() {
      var _this3 = this;

      this.$utils.Tools.loadShow();
      this.$api.Repair.approvalRepair({
        checkId: this.checkId,
        xh: this.xh,
        result: this.result,
        userSerial: this.$utils.Store.getItem('userSerial'),
        reason: this.reason
      }).then(function (response) {
        _this3.$utils.Tools.loadHide();

        var code = response.code,
            msg = response.msg;

        if (+code !== _this3.$code.success) {
          _this3.$utils.Tools.toastShow(msg);
        } else {
          _this3.$utils.Tools.toastShow(msg);

          setTimeout(function () {
            window.location.reload(); // this.$router.go(0);
          }, 1000);
        }
      }).catch(function () {
        _this3.$utils.Tools.httpError();
      });
    },
    hideReason: function hideReason() {},
    onCancel: function onCancel() {},

    /**
     *  function删除请假申请接口
     *  @wzx
     *  @2018-12-25
     **/
    onConfirm: function onConfirm() {
      var _this4 = this;

      this.$utils.Tools.loadShow();
      this.$api.Repair.deleteRepair(this.xh, this.$utils.Store.getItem('userSerial'), '').then(function (response) {
        _this4.$utils.Tools.loadHide();

        var code = response.code,
            msg = response.msg;

        if (+code !== _this4.$code.success) {
          _this4.$utils.Tools.toastShow(msg);
        } else {
          setTimeout(function () {
            window.location.reload(); // this.$router.go(0);
          }, 1000);
        }
      }).catch(function () {
        _this4.$utils.Tools.httpError();
      });
    },
    onHide: function onHide() {},
    onShow: function onShow() {},
    logIndexChange: function logIndexChange() {},
    show: function show(index) {
      this.$refs.previewer.show(index);
    },
    pushHistory: function pushHistory() {
      var state = {
        title: "title",
        url: "#"
      };
      window.history.pushState(state, state.title, state.url);
    }
  },
  created: function created() {
    var strs = [];

    if (this.$utils.Store.getItem('state')) {
      strs = decodeURI(this.$utils.Store.getItem('state')).split(",");
      this.xh = strs[0];
      this.genre = strs[1];
    } else {
      this.$utils.Store.setItem('state', this.$route.params.state);
      strs = decodeURI(this.$route.params.state).split(",");
      this.xh = strs[0];
      this.genre = strs[1];
    }

    this.getRepairDetail();
  },
  mounted: function mounted() {
    if (this.$route.params.notification) {
      this.pushHistory();
    }
  },
  destroyed: function destroyed() {
    this.$utils.Store.removeItem('state');
  }
};